import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, Button, ArrowRight } from '@yourmileag/ui-kit';

export function Login(props) {
    const { t } = useTranslation();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        var basicauth = localStorage.getItem('basicauth');
        if (basicauth) {
            onLogin(basicauth);
        }
    }, []);

    function onUsernameChange(event) {
        const value = event.target.value;
        setUsername(value);
    }

    function onPasswordChange(event) {
        const value = event.target.value;
        setPassword(value);
    }

    function onLogin(basicauth) {
        var url = props.config.loginUrl;

        if (!basicauth) {
            basicauth = window.btoa(username + ':' + password);
        }

        var request = new XMLHttpRequest();
        request.responseType = 'json';
        request.open('post', url);
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onload = function () {
            if (request.response) {
                localStorage.setItem('basicauth', basicauth);
                props.onSuccess(request.response);
            } else {
                setError(true);
            }
        };

        request.send(JSON.stringify({
            basicauth: basicauth,
        }));
    }

    function onSubmit(event) {
        event.preventDefault();
        onLogin();
    }

    return (
        <div className={'login'}>
            <div className={'content'}>
                <form onSubmit={onSubmit}>
                    <img className={'login__brand'} src={props.config.ui.logo} />

                    <fieldset>
                        <div className={'form_row'}>
                            <TextInput name={'username'} label={t('username')} clean required value={username} onChange={onUsernameChange} />
                        </div>
                        <div className={'form_row'}>
                            <TextInput name={'password'} type="password" label={t('password')} clean required value={password} onChange={onPasswordChange} />
                        </div>
                    </fieldset>

                    {error && (
                        <p class="login__error">{t('invalidCredentials')}</p>
                    )}

                    <Button type={'submit'} className={'custom_button'} variant={'special'} size={'l'} icon={<ArrowRight />}>
                        {t('login')}
                    </Button>
                </form>
            </div>
        </div>
    );
}
