import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Clock, FleetColumn, OriginDestinationColumn, StatusSmall, Status, VehicleBusiness, VehicleSmall, VehicleStandard, VehicleVan, VehicleWagon, VehicleWheelchairTaxi, Button, Eye, Trash } from '@yourmileag/ui-kit';
import { stateLabels } from '../helpers';


export function OrderItem(props) {
    const { t } = useTranslation();

    const order = props.order;

    const startTime = moment.unix(order.estimatedTimespan.startTimestamp).format('HH:mm');
    const endTime = moment.unix(order.estimatedTimespan.endTimestamp).format('HH:mm');

    const iconMap = new Map([
        ['standard', <VehicleStandard />],
        ['business', <VehicleBusiness />],
        ['van', <VehicleVan />],
        ['wagon', <VehicleWagon />],
        ['wheelchairTaxi', <VehicleWheelchairTaxi />],
        ['small', <VehicleSmall />],
    ]);

    const getFleetIcon = (category) => iconMap.get(category);

    function onDetails() {
        props.setOrder(order._id);
    }

    function onCancel() {
        if (window.confirm(t('cancelOrderConfirmationText'))) {
            console.log('yes');
            props.cancelOrder(order);
        } else {
            console.log('no');
        }
    }

    return (
        <div className={'order__item'}>
            <div className={'order__item__status'}>
                <Status status={order.status} stateLabels={stateLabels} />
            </div>
            <div className={'order__item__name'}>
                {order.customer.lastName + ' ' + order.customer.firstName}
            </div>
            <div className={'order__item__route'}>
                <OriginDestinationColumn
                    origin={order.origin}
                    hasStopovers={order.stopovers.length > 0}
                    destination={order.destination}
                />
            </div>
            <div className={'order__item__info'}>
                <div className={'order__item__info__item'}>
                    <FleetColumn icon={<Clock />} car={`${startTime} - ${endTime}`} />
                </div>
                <div className={'order__item__info__item'}>
                    <FleetColumn icon={getFleetIcon(order.fleet.icon)} car={order.fleet.label} />
                </div>
            </div>
            <div className={'order__item__action'}>
                {['new', 'assigning', 'assigned', 'problem'].indexOf(order.status) >= 0 && (
                    <>
                        <Button size={'m'} onPress={onCancel} iconLeft={<Trash />}>
                            {t('cancelOrder')}
                        </Button>
                        &nbsp;&nbsp;
                    </>
                )}
                <Button className={'custom_button'} variant={'special'} size={'m'} onPress={onDetails} iconLeft={<Eye />}>
                    {t('details')}
                </Button>
            </div>
        </div>
    );
}
