import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

//import './styles/reset.css';
import './styles/fonts.css';
import './styles/index.css';
import './styles/widget.css';
import './styles/app.css';
import './styles/form.css';
import './styles/card.css';
import './styles/typo.css';
import './styles/waypoints.css';
import './styles/slide.css';
import './styles/stats.css';
import './styles/login.css';
import './styles/order.css';

import Widget from './Widget';
import { setLanguage } from './i18n';

const defaultKey = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvd25lciI6InNlcnZlciIsImlkZW50aXR5SWQiOiJ3aWRnZXQiLCJzY29wZXMiOnsiZXNzZW50aWFscyI6WyJvcmRlcnM6cmVhZCJdfSwianRpIjoiMDAwMDAwMDAyIn0.PPPPhUNXoaYrT0xf7gaZsTajiAKrSpgjk9-nAqpTiz65nTLYCHOB72kkriZGIpt44O7OSzwVcOfAMvxr2eLWuMVsbzsCgpb1MD5Kc278pSXM5gJpAiVJ_Pk0ZvGqJ-mJKiavlC-rukI5WuhVLGsoSFvh2dCjMy2_dTPLavfSQ5CgwDiuk7mRSTulZC0izEJd0wSiwqp4csqaR4_sg2hap67EkBKGqqdhuegUxBA4kblT9v6Op2AnKGEvPNy3AzVBbRUjNH0DMxQHk3wvc7O1dbfJmw_4WXB8r0ThMYbKLfo-DunjUzGs1dptmA9FSKGy0_je1_DmasvIdJowny4qdw';

let widgetEl = document.getElementById('go_widget');

let key = widgetEl.getAttribute('data-key');
let lang = widgetEl.getAttribute('data-lang');
let app = widgetEl.getAttribute('data-app') == 'true';
let fms = widgetEl.getAttribute('data-fms') == 'true';
let logo = widgetEl.getAttribute('data-logo');
let phone = widgetEl.getAttribute('data-phone');
let colorLight = widgetEl.getAttribute('data-color-light');
let colorDark = widgetEl.getAttribute('data-color-dark');
let useBlockedTime = widgetEl.getAttribute('data-use-blocked-time') == 'true';
let loginUrl = widgetEl.getAttribute('data-login-url');
let allowedTypes = widgetEl.getAttribute('data-allowed-types').split(',');
let useFixedPrice = widgetEl.getAttribute('data-use-fixed-price') ? widgetEl.getAttribute('data-use-fixed-price') == 'true' : true;

setLanguage(lang);

const config = {
  lang: lang,
  fms: fms,
  app: app,
  api: {
    url: 'https://api-beta.go-fleet.ch',
    key: key ? key : defaultKey,
  },
  phone: phone,
  ui: {
    logo: logo,
    colorLight: colorLight,
    colorDark: colorDark,
  },
  useBlockedTime: useBlockedTime,
  useFixedPrice: useFixedPrice,
  timezone: 'Europe/Zurich',
  blockedTimeStart: '22:30:00',
  blockedTimeEnd: '06:00:00',
  loginUrl: loginUrl,
  fleetIds: [],
  allowedTypes: allowedTypes,
}

ReactDOM.render(<Widget config={config} />, widgetEl);
