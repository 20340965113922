import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Frame } from './Frame';
import { OrderItem } from './OrderItem';
import { Footer } from './Footer';

export function OrderList(props) {
    const { t } = useTranslation();

    const [activeOrders, setActiveOrders] = React.useState([]);
    const [pastOrders, setPastOrders] = React.useState([]);

    function getActiveOrders() {
        var url = props.config.api.url + '/essentials/orders?q=[eq]partnerName:' + props.config.partnerName + ';status:new,assigning,assigned,arriving,arrived,completing,problem'
        var request = new XMLHttpRequest();
        request.responseType = 'json';
        request.open('get', url);
        request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
        request.onload = function () {
            setActiveOrders(request.response.items);
        };
        request.send();
    }

    function getPastOrders() {
        var url = props.config.api.url + '/essentials/orders?q=[eq]partnerName:' + props.config.partnerName + ';status:completed,canceled'
        var request = new XMLHttpRequest();
        request.responseType = 'json';
        request.open('get', url);
        request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
        request.onload = function () {
            setPastOrders(request.response.items);
        };
        request.send();
    }

    function cancelOrder(order) {
        var url = props.config.api.url + '/essentials/orders/' + order._id;
        var request = new XMLHttpRequest();
        request.responseType = 'json';
        request.open('PATCH', url);
        request.setRequestHeader('Authorization', 'Bearer ' + props.config.api.key);
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onload = function () {
            getActiveOrders();
            getPastOrders();
        };
        var data = {
            status: 'canceled',
            cancellationTrigger: 'widget',
        }

        // TODO: maybe move to server
        const now = moment().unix();
        if (order.timespan) {
            if (order.timespan.startTimestamp < now) {
                data.timespan = { endTimestamp: now };
            }
        } else {
            if (order.estimatedTimespan.startTimestamp < now) {
                data.estimatedTimespan = { endTimestamp: now };
            }
        }

        request.send(JSON.stringify(data));
    }

    React.useEffect(() => {
        getActiveOrders();
        getPastOrders();
    }, []);

    return (
        <div className={'slide'}>
            <Frame>
                <div className={'content'}>
                    {activeOrders.length > 0 && (
                        <p className={'title'}>{t('activeOrders')}</p>
                    )}
                    {activeOrders.map(function (order) {
                        return (
                            <OrderItem order={order} setOrder={props.setOrder} cancelOrder={cancelOrder} />
                        )
                    })}
                    {pastOrders.length > 0 && (
                        <p className={'title'}>{t('pastOrders')}</p>
                    )}
                    {pastOrders.map(function (order) {
                        return (
                            <OrderItem order={order} setOrder={props.setOrder} cancelOrder={cancelOrder} />
                        )
                    })}
                </div>
                <Footer config={props.config}/>
            </Frame>
        </div>
    );
}
